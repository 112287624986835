.card{
    padding:20px;
    max-width:500px;
    min-width:300px;
    margin: 0 auto;
    border:1px solid gainsboro;
    border-radius: 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.form-container div{
    display:flex;
    align-items: flex-start;
    justify-content:center;
    flex-direction: column;
    margin:10px 0 10px 0;
}

.form-control{
    height: 38px;
    width: 100%;
    border:none;
    border-bottom: 1px solid indianred;
}

.form-control:focus{
    border:none;
    outline: none;
    border-bottom: 1px solid aquamarine;
}

.btn{
    height:38px;
}
.btn-primary{
    background-color: indianred;
    border:none;
    border-radius: 10px;
    color:white;
}
