.order-box{
    max-width: 800px;
    margin-top:15px;
    margin-right: 15px;
    margin-left: 15px;
}

.order-table__user{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.order-table__top{
    display:flex;
    justify-content: flex-start;
    flex-direction: row;
}

.order-table__top span{
    margin-right: 10px;
    font-weight: bolder;
}

.order-table__user span{
    font-weight: bold;
    color: black;
}

.order-table{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
}

.order-table span{
    font-weight: bold;
}

.order-table__product{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed black;
    margin-bottom: 10px;
    padding-bottom:10px;
}

.order-table__box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.order-table__header{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px dashed;
}

.order-table_controls ul{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom:20px;
}

.order-table_controls ul li{
    cursor:pointer;
    color:white;
}

.order-table__header > div{
    padding:10px;
}

.order-table__image{
    width: 90px;
    margin-right: 10px;
}

.order-table__info{
    flex-shrink: 2;
}

.order-table div{
    margin-top:8px;
}

.order-table__info span, .order-table__total span{
    font-weight: bold;
    color:black;
}

.order-table__total{
    margin-right: 20px;
    display: flex;
    flex-shrink: 1;
    align-items: center;
    justify-content: flex-end;
}

.order-table__expense{
    font-weight: bold;
    text-align: right;
    margin-right: 5px;
    font-size: 20px;
    color: red;
}

.order-table__title{
    margin-top:20px;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.class-removed{
    opacity: 0.3;
}

.export-to-excel{
    padding:3px;
    margin-bottom:5px;
    cursor:pointer;
}