.products{
    margin-left:15px;
    margin-right:15px;
    padding:10px;
}
.products td, .products th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    border-spacing: 0;
}
.products td {
    display: table-cell;
    vertical-align: inherit;
}

.products th{
    background-color: bisque;
}

.products tr{
    color: black;
}

.products tr:hover,
.products tr:hover a {
    background-color: gray;
    color:white;
    cursor:pointer;
}

.products .products-delete{
    cursor: pointer;
}   

.products table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    text-indent: initial;
}
.btn-wrapper{
    margin-bottom: 20px;
}
.btn-wrapper a{
    color: white;
    background-color: chocolate;
    border-radius: 10px;
    padding:7px;
}