.add-product{
    padding:20px;
    max-width:250px;
    min-width:700px;
    margin: 0 auto;
    margin:20px;
    border:1px solid gainsboro;
    border-radius: 20px;
}

.form-container div{
    display:flex;
    align-items: flex-start;
    justify-content:center;
    flex-direction: column;
    margin:10px 0 10px 0;
}

.form-control{
    height: 38px;
    width: 100%;
    border:none;
    border-bottom: 1px solid indianred;
    margin-top:10px;
}

.form-select{
    height: 38px;
    width: 100%;
    border:none;
    border-bottom: 1px solid indianred;
    margin-top:10px;
    background-color: white;
}

.form-control:focus{
    border:none;
    outline: none;
    border-bottom: 1px solid aquamarine;
}

.btn{
    height:38px;
    padding:0 10px 0 10px;
    margin-top:10px;
    cursor: pointer;
}
.btn-primary{
    background-color: indianred;
    border:none;
    border-radius: 10px;
    color:white;
}

.edit-products{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.edit-products img{
    width: 100px;
}

.form-editpage div{
    font-weight: bold;
    font-size: 15px;
    margin-bottom:20px;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
