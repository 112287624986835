.order-finish{
    max-width: 500px;
    margin:0 auto;
    padding:40px;
}

.order-finish__status{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.order-finish__status h6{
    margin:0;
}

.order-finish__messages{
    margin:10px;
    padding:10px;
    border-radius: 10px;
    background-color:aquamarine;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    height: 50vh;
}

.order-finish__message{
    display: flex;
    margin:5px;
}

.order-finish__message div{
    background-color: blanchedalmond;
    border-radius: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding:5px;
}

.message-date{
    font-size: 10px;
}

.left-msg{
    flex-direction: row;
}

.right-msg{
    flex-direction: row-reverse;
}

.order-finish__input-message form{
    display:flex;
    flex-wrap: wrap;
}

.order-finish__input-message form textarea{
    width: 100%;
}

.order-finish__input-message form textarea,
.order-finish__input-message form button{
    height: 125px;
    margin:0;
    padding:0;
    margin:10px;
    padding:10px;
}

.order-finish--total_frete{
    display:flex;
    align-items: flex-end;
    flex-direction: column;
    margin-bottom: 20px;
    font-weight: bold;
}