.products-widget{
    position: fixed;
    right: 0;
    bottom:0;
    border:5px solid #7bcbb7;
    z-index:99999;
    background: white;
    max-height: 100%;
    overflow-y: scroll;
}

.products-widget__ul{
    height: 100%;
    overflow-y: scroll;
}

.products-widget__li{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(255 255 255);
    padding: 4px;
    border-bottom: 1px dashed #7bcbb7;
}

.products-widget__buttons{
    background: #7bcbb7;
    color: white;
    cursor: pointer;
    text-align: center;
}

.products-widget__remove{
    height: 53px;
    background: #ee6566;
    margin: 0 10px 0 10px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}

.products-widget__container{
    display:flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    color:black;
}

.products-widget__items{
    margin-left:10px;
}

.products-widget__img img{
    width:65px;
    padding:2px;
    border-radius: 10px;
}

.hidden{
    display:none;
}