.clients{
    margin-left:15px;
    margin-right:15px;
    padding:10px;
}
.clients td, .clients th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    border-spacing: 0;
}
.clients td {
    display: table-cell;
    vertical-align: inherit;
}

.clients th{
    background-color: bisque;
}

.clients tr{
    color: black;
}

.clients tr:hover,
.clients tr:hover a {
    background-color: gray;
    color:white;
    cursor:pointer;
}

.clients .clients-delete{
    cursor: pointer;
}   

.clients table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    text-indent: initial;
}
.btn-wrapper{
    margin-bottom: 20px;
}
.btn-wrapper a{
    color: white;
    background-color: chocolate;
    border-radius: 10px;
    padding:7px;
}