.client-order{
    margin:0 auto;
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.client-order div div{
    margin: 20px;
}

.client-order__form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px;
}

.client-order__form input{
    height: 30px;
    font-size: 20px;
    margin-top: 10px;
    margin-right: 10px;
    width: 206px;
    border: none;
    border-bottom: 1px solid black;
}

.client-order__form input:focus{
    outline: none;
}

.checkout__table_tr{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkout__table_tr .__amount input{
    max-width: 70px;
    border:none;
    border-bottom: 1px solid gray;
    padding-bottom:5px;
    outline: none;
}

input[type=number]::-webkit-inner-spin-button { 
    all: unset; 
    min-width: 21px;
    min-height: 45px;
    margin: 17px;
    padding: 0px;
    background-image: 
    linear-gradient(to top, transparent 0px, transparent 16px, #fff 16px, #fff 26px, transparent 26px, transparent 35px, #000 35px,#000 36px,transparent 36px, transparent 40px),
    linear-gradient(to right, transparent 0px, transparent 10px, #000 10px, #000 11px, transparent 11px, transparent 21px);
    transform: rotate(90deg) scale(0.8, 0.9);
    cursor:pointer;
  }
