.dashboard{
    margin-left:10px;
    margin-right:10px;
}

.table-dashboard__total{
    color:white;
    background-color: red;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    text-decoration:underline;
}