.header-admin{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 20vw;
    height: 100vh;
    float: left;
    background:#017e46;
    position:fixed;
}

.header-admin__navbar li a{
    color:white;
}

.main-admin{
    float: left;
    width: 80vw;
    margin-left: 20vw;
}

.billboard-dashboard{
    margin-top:20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.billboard-dashboard div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    flex-shrink: 1;
    width: 22%;
    height: 100px;
    background-color: white;
    border:5px solid rgb(238, 235, 235);
    -webkit-box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
    -moz-box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
    box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
}

.billboard-dashboard div span{
    font-size: 37px;
    font-weight: bold;
    color:#017e46;
}

.recent-orders{
    border-radius: 20px;
    margin-top:20px;
    background-color: white;
    border:1px solid rgb(238, 235, 235);
    -webkit-box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
    -moz-box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
    box-shadow: 28px 20px 22px -4px rgba(245,245,245,1);
    padding:5px;
}

.recent-orders__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:5px;
}

.recent-orders__top span{
    color: #017e46;
    font-size: 18px;
    font-weight: bold;
}

.recent-orders__top button{
    background-color: #017e46;
    color: white;
    padding:5px;
    border-radius: 5px;
    border:none;
}

.table-dashboard {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}
  
.table-dashboard td, .table-dashboard th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
}

.cancel{
    background-color: orangered;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.cart{
    background-color: orange;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.revision{
    background-color: yellow;
    color: black;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.revised{
    background-color: violet;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.pending{
    background-color: blue;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.sent{
    background-color: aqua;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.payment{
    background-color: chartreuse;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}


.done{
    background-color: #017e46;
    color: white;
    padding: 5px;
    border-radius: 5px;
    border: none;
}
